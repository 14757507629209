unit uEmail;

interface

uses
  System.SysUtils,
  Web,
  WEBLib.REST;

function ValidateMobileNumber(MobileNumber: String): Boolean;

function ValidateEmail(Email: String): Boolean;
procedure SendEmail(Sender, ReplyTo, MailSubject, MailBody, recipients, cc: String; RetryCounter: Integer = 0);

implementation

function ValidateMobileNumber(MobileNumber: String): Boolean;
begin
  asm
    let re = /^[+]?[0-9()]+$/;
    Result = re.test(MobileNumber);
  end;
end;

function ValidateEmail(Email: String): Boolean;
begin
  asm
    let re = /\S+@\S+\.\S+/;
    Result = re.test(Email);
  end;
end;

procedure SendEmail(Sender, ReplyTo, MailSubject, MailBody, recipients, cc: String; RetryCounter: Integer);
const
  ReplaceFrom: String = 'https://sideminds.cymeg.com';
  ReplaceTo: String = 'http://services.cymeg.com:8082';
var
  Email: THttpRequest;
begin
  Email := THttpRequest.Create(nil);

  Email.URL := 'https://sideminds.cymeg.com/empire/sideminds/SidemindsService/SendMail';
  if (LowerCase(document.documentURI).contains('localhost')) then
    Email.URL := Email.URL.replace(ReplaceFrom,ReplaceTo);

  Email.Command := httpPOST;
  Email.Headers.Clear;
  Email.Headers.AddPair('Content-Type','application/json');
  Email.Headers.AddPair('tenant-url','https://sideminds.cymeg.com/');
  Email.PostData := '{' +
    '"Sender": "' + Sender + '",' +
    '"replyto": "' + ReplyTo + '",' +
    '"MailSubject": "' + MailSubject + '",' +
    '"MailBody": "' + MailBody + '",' +
    '"recipients": "' + recipients + '",' +
    '"cc": "' + cc + '"' +
  '}';

  Email.Execute(
    procedure(AResponse: string; AReq: TJSXMLHttpRequest)
    begin
      Email.Free;

      if (AReq.Status <> 200) AND (RetryCounter < 6) then
        SendEmail(Sender, ReplyTo, MailSubject, MailBody, recipients, cc, RetryCounter+1);
    end
  );
end;


end.
